.nav-link {
    cursor: pointer;
    color:rgb(0, 0, 0) !important;
}

.dark-theme .nav-link {
    color:rgb(255, 255, 255) !important;
}

.navbar {
    box-shadow: .5px .5px 10px rgb(0, 0, 0);
}


.dark-theme .navbar {
    box-shadow: .5px .5px 10px rgb(42, 6, 205);
}

.dark-theme .nav-link:hover{
    color: rgb(237, 229, 0) !important;
}

.nav-link:hover {
    font-weight: bolder;
}

.dark-theme .nav-logo:hover {
    filter: brightness(0) 
    invert(100%) 
    sepia(20%) 
    saturate(6037%);
}

.nav-logo:hover {
    transform: scale(1.03);
    transition: transform 0.1; 
    filter: brightness(50);
}


.nav-logo {
    width: 170px;
    height: auto;
    cursor: pointer;
}

.nav-toggle {
    margin-left: 50px;
}

@media (max-width: 991px) {
    .nav-toggle {
        margin-left: 0px;
    }
}