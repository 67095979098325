.hero-container {
    height: 100vh; 
    display: flex;
    flex-direction: column; 
    justify-content: flex-start;
    padding: 10rem 0;
}

.hero-section {
    background-image: url('../../assets/backgrounds/spiral.png');
    background-repeat: no-repeat; 
    background-attachment: fixed;
    padding-top: 30px;
}

.dark-theme .hero-section {
    background-image: url('../../assets/backgrounds/dark-mode-double-spiral-flipped.png');
    background-repeat: no-repeat; 
    background-attachment: fixed;
}


.globe {
    margin-right: 133px;
}

.school {
    margin-right: 25px;
}
  
.image-column {
    width: 100%;
}

.text-column {
    width: 100%;
}

.reach-out-button {
    cursor: pointer;
    background-color: #3c0640 !important;
    border: 1px solid #3c0640 !important;
    box-shadow: 3px 3px 10px rgb(0, 0, 0);
}

.reach-out-button:hover {
    transform: scale(1.1);
    transition: transform .1; 
}

.dark-theme .reach-out-button {
    background-color: #3c0640 !important;
    border: 1px solid #3c0640 !important;
    box-shadow: 3px 3px 10px rgb(42, 6, 205);
}

.sub-hed-text, .hello-text {
    text-align: center;
}

.icon-container {
    text-align: center;
}

.logo:hover {
    transform: scale(1.1);
    transition: transform .1; 
}

.hero-section-button {
    padding: 20px;
}

.scroll-container {
    padding-top: 100px;
}


.scroll-down {
    width: 32px;
    height: 32px;
	border-radius: 50%;
    animation: bounce 2s infinite;
    cursor: pointer;
}

@keyframes bounce {
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-10px);
    }
}

.hero-image {
    box-shadow: 3px 3px 10px rgb(0, 0, 0);
}

.dark-theme .hero-image {
    box-shadow: 3px 3px 10px rgb(42, 6, 205);
}
