.work-section {
    background-image: url('../../assets/backgrounds/blue-line-spiral.png');
    background-repeat: no-repeat; 
    background-attachment: fixed;
    padding-top: 100px; 
    padding-bottom: 100px;
}

.dark-theme .work-section {
    background-image: url('../../assets/backgrounds/dark-mode-double-spiral-flipped.png');
    background-repeat: no-repeat; 
    background-attachment: fixed;
}

.vertical-timeline::before {
    background: black !important;
    box-shadow: 3px 3px 8px rgb(0, 0, 0);
}

.icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.tech-badge {
    border-radius: 40% !important;
    font-size: .70em !important
}

.date {
    color: #000000;
}

@media (max-width: 1168px) {
    .date {
      color: #fff; 
    }
  }

.dark-theme .date {
    color: #ffffff;
    background-color: transparent !important;
}


.dark-theme .vertical-timeline::before {
    background: white !important;
    box-shadow: 5px 3px 8px rgb(42, 6, 205);
}
  