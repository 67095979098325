.contact-section {
  background-image: url('../../assets/backgrounds/flipped-spiral.png');
  background-repeat: no-repeat; 
  background-attachment: fixed;
  padding-top: 100px; 
  padding-bottom: 100px;
}

.dark-theme .contact-section {
  background-image: url('../../assets/backgrounds/dark-mode-double-spiral.png');
  background-repeat: no-repeat; 
  background-attachment: fixed;
}

.contact-container {
    padding-top: 100px; 
    padding-bottom: 100px;
  }

  .reach-out-button {
    cursor: pointer;
    background-color: #3c0640 !important;
    border: 1px solid #3c0640 !important;
    box-shadow: 3px 3px 10px rgb(0, 0, 0);
}

.dark-theme .reach-out-button {
    background-color: #3c0640 !important;
    border: 1px solid #3c0640 !important;
    box-shadow: 3px 3px 10px rgb(42, 6, 205);
}

.reach-out-button:hover {
    transform: scale(1.1);
    transition: transform .1; 
}

#formName, #formBasicEmail, #formMessage {
  box-shadow: 3px 3px 5px rgb(0, 0, 0) !important;
}

.dark-theme #formName, .dark-theme #formBasicEmail, .dark-theme #formMessage {
  box-shadow: 3px 3px 10px rgb(42, 6, 205) !important;
}

.success-message {
  padding-bottom: 100px;
  padding-top: 100px;
}

.fail-message {
  padding-bottom: 100px;
  padding-top: 100px;
  color: red;
  font-weight: bolder;
}