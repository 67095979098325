.projects-section {
  background-image: url('../../assets/backgrounds/double-spiral.png');
  background-repeat: no-repeat; 
  background-attachment: fixed;
}

.dark-theme .projects-section {
  background-image: url('../../assets/backgrounds/dark-mode-double-spiral.png');
  background-repeat: no-repeat; 
  background-attachment: fixed;
}

.projects-container {
    padding-top: 100px; 
    padding-bottom: 100px;
  }

.project-card {
  box-shadow: 3px 3px 10px rgb(0, 0, 0);
}

.dark-theme .project-card {
  box-shadow: 3px 3px 10px rgb(42, 6, 205);
}

.flex-grow {
  display: flex;
  flex-direction: column;
}

.button-wrapper {
  margin-top: auto;
}

.tech-wrapper {
    margin-top: auto;
}

.card-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
}