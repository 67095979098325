.about-section {
    background-image: url('../../assets/backgrounds/spiral.png');
    background-repeat: no-repeat; 
    background-attachment: fixed;
}

.dark-theme .about-section {
  background-image: url('../../assets/backgrounds/dark-mode-double-spiral-flipped.png');
  background-repeat: no-repeat; 
  background-attachment: fixed;
}

.card-style {
    display: flex;
    flex-direction: column; 
    justify-content: center;
    border: 1px solid black !important;
    background-color: #3c0640 !important;
    color: white !important;
    box-shadow: 3px 3px 10px rgb(0, 0, 0);
}

.dark-theme .card-style {
  box-shadow: 3px 3px 15px rgb(42, 6, 205);

}